<template>
  <div id="videoData">
    <div class="c-query">
      <div class="item">
        <span>选择设备：</span>
        <el-select v-model="devices" multiple collapse-tags filterable clearable placeholder="请选择">
          <el-option v-for="item in deviceOptions" :key="item.id" :label="item.title" :value="item.id">
          </el-option>
        </el-select>
        <el-button type="primary" icon="el-icon-search" style="margin-left: 10px;" @click="query">查询</el-button>
      </div>
      <div class="item">
        <el-button type="primary" icon="el-icon-video-play" @click="playAll">一键播放</el-button>
        <el-button type="primary" plain icon="el-icon-video-pause" @click="pauseAll">一键停止</el-button>
      </div>
    </div>
    <div class="container-outer">
      <!-- 视频 -->
      <div class="datas" :class="[page.measure=='small'?'small_size':'large_size']">
        <div class="item" v-for="item of deviceArrFilter" :key="item.id">
          <div class="video">
            <video :id="'player_'+item.id" muted autoplay controls playsInline webkit-playsinline style="width: 100%; height: 210px; max-height: 100%;" :src="item.videoConfig.url">
              <source :src="item.videoConfig.url" type="application/x-mpegURL">
            </video>
          </div>
          <div class="data">
            <div style="border-bottom: 1px solid #DDDDDD; margin-bottom: 8px;">
              <p>
                <span>设备：</span>
                <span>{{item.title==""?item.id:item.title}}</span>
              </p>
            </div>
            <p v-for="item2 of item.datas">
              <span>{{item2.name}}：</span>
              <span>{{item2.value}} {{item2.unit}}</span>
            </p>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <div class="pager" v-show="deviceArr.length>0">
        <div class="size">
          <span>尺寸：</span>
          <el-select v-model="page.measure" size="mini" placeholder="请选择">
            <el-option key="small" label="4:3" value="small"></el-option>
            <el-option key="large" label="16:9" value="large"></el-option>
          </el-select>
        </div>
        <el-pagination background :page-sizes="page.sizes" :page-size="page.size" :current-page="page.curPage" layout="total, sizes, prev, pager, next" :total="deviceArr.length" @size-change="sizeChange" @current-change="pageChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import ezuikit from '../../common/hk1.3/1.3/ezuikit.js';

export default {
  data() {
    const sizes = new Array();
    for (let i = 6; i <= 36; i++) {
      sizes.push(i);
    }
    return {
      players: [],
      deviceArr: [],
      //查询选中的设备
      devices: [],
      // page
      page: {
        sizes: sizes,
        size: 9,
        curPage: 1,
        measure: 'large' //尺寸：small-4:3，large-16:9
      },
      timer: null
    }
  },
  computed: {
    // 所有设备
    deviceOptions() {
      return this.$store.getters.getAllDevices;
    },
    // 当前选中节点设备
    // deviceArr() {
    // 	return this.$store.getters.getCurDevices;
    // },
    // 选中设备分页
    deviceArrFilter() {
      let page = this.page;
      let arr = this.deviceArr.filter((item, index) => index >= ((page.curPage - 1) * page.size) && index <= ((
        page.curPage * page.size) - 1));
      return arr;
    },
  },
  watch: {
    '$store.getters.getCurDevices': {
      handler(newVal) {
        this.devices = [];
        this.deviceArr = newVal;
      }
    },
    deviceArr() {
      this.page.curPage = 1; // 重置分页
      this.setHotDevices(); // 设置热点设备
      this.videoConfigRequest(); //获取视频配置
    }
  },
  methods: {
    // 查询
    query() {
      let arr = new Array();
      for (let item of this.devices) {
        let device = this.common.cFindObj(this.deviceOptions, "id", item);
        if (device != null) {
          arr.push(device);
        }
      }
      this.deviceArr = arr;
    },

    // 设置热点设备
    setHotDevices() {
      let uids = "";
      this.deviceArrFilter.forEach((item) => {
        uids += item.id + ",";
      })
      let json = {
        "cmd": "setHotDevices",
        "uids": uids
      }
      this.ws.send(json);
    },

    // 获取视频配置
    videoConfigRequest() {
      let json;
      // 兼容华为云视频和海康云视频
      if (this.common.featureEnabled('huaweiVideo')) {
        this.deviceArrFilter.forEach((item) => {
          // 华为云视频
          json = {
            "cmd": "videoUrl",
            "uid": item.id,
            "type": this.common.videoType(),  // 支持hls,rtmp和flv,不传时默认为hls
          }
          // console.log('---华为云视频参数---',json)
          this.ws.send(json);
        })
      } else {
        let uids = "";
        this.deviceArrFilter.forEach((item) => {
          uids += item.id + ","
        })
        // 海康云视频
        let json = {
          "cmd": "dataStore",
          "id": uids,
          "tag": "video"
        }
        // console.log('---海康云视频参数---',json)
        this.ws.send(json);
      }


    },

    // 视频初始化
    initVideo() {
      this.players = [];
      this.deviceArrFilter.forEach((item) => {
        this.players.push({
          id: "player_" + item.id,
          player: null
        });
      })
      setTimeout(() => {
        for (let item of this.players) {
          if (item.player) {
            item.player = null;
          }
          item.player = new EZUIPlayer(item.id);
        }
        this.startInterval();
      }, 1000)
    },
    // 开始计时
    startInterval() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      // 一分钟后暂停所有视频
      this.timer = setTimeout(() => {
        this.pauseAll();
      }, 60 * 1000);
    },

    // 一键播放/停止
    playAll() {
      this.players.forEach((item) => {
        if (item.player) {
          item.player.play();
        }
        this.startInterval();
      })
    },
    pauseAll() {
      this.players.forEach((item) => {
        if (item.player) {
          item.player.pause();
        }
      })
    },

    // 分页——当前页改变
    sizeChange(size) {
      this.page.size = size;
      this.page.curPage = 1;
      this.setHotDevices();
      this.videoConfigRequest();
    },
    pageChange(curPage) {
      this.page.curPage = curPage;
      this.setHotDevices();
      this.videoConfigRequest();
    },
    // 视频云返回参数
    videoStoreAckFun(res) {
      for (let item of res.items) {
        let data = item.data;
        if (JSON.stringify(data) != "{}") {
          let device = this.common.cFindObj(this.deviceArrFilter, "id", item.id);
          if (device != null) {
            device.videoConfig = {
              url: data.url ? data.url : '',
              id: data.id ? data.id : ''
            }
          }
        }
      }
      this.initVideo();
    }
  },
  mounted() {
    this.deviceArr = this.$store.getters.getCurDevices;
    // 视频配置 [海康云视频]
    this.ws.addCallback('dataStoreAck', (res) => {
      // console.log('---海康云视频---',res)
      this.videoStoreAckFun(res)
    })
    // 视频配置 [华为云视频]
    this.ws.addCallback('videoUrlAck', (res) => {
      // console.log('---华为云视频---',res)
      // this.videoStoreAckFun(res)
      let device = this.common.cFindObj(this.deviceArrFilter, "id", res.uid);
      if (device != null) {
        device.videoConfig = {
          url: res.url || '',
          id: res.uid || ''
        }
      }
      this.initVideo();
    })
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }
}
</script>

<style lang="scss">
$item-w: 400px; //item宽度

#videoData {
  // 查询过滤
  .c-query {
    justify-content: space-between;

    .item {
      margin-right: 0;
    }
  }

  // 视频展示

  .small_size {
    grid-template-columns: repeat(auto-fill, 420px);
    // grid-template-columns: repeat(auto-fill, minmax(420px, 1fr)); //minmax(最小宽度，最大宽度)
    // grid-template-rows: repeat(auto-fill, minmax(210px, 1fr)); //minmax(最小宽度，最大宽度)
  }

  .large_size {
    grid-template-columns: repeat(auto-fill, 510px);
    // grid-template-columns: repeat(auto-fill, minmax(510px, 1fr)); //minmax(最小宽度，最大宽度)
    // grid-template-rows: repeat(auto-fill, minmax(210px, 1fr)); //minmax(最小宽度，最大宽度)
  }

  .container-outer {
    flex: auto;
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .datas {
      display: grid;
      grid-template-rows: 210px;
      grid-row-gap: 20px; //行间距
      grid-column-gap: 40px; //列间距
      justify-content: space-between;

      .item {
        display: flex;
        flex-direction: row;

        &:hover {
          // @include c-hover;
        }

        .video {
          flex: auto;

          video {
            object-fit: fill;
          }
        }

        .data {
          width: 120px;
          height: 210px;
          flex-shrink: 0;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          padding: 10px;
          background-color: $color_white;
          overflow: auto;

          p {
            font-size: 12px;
            margin: 0;
            margin-bottom: 8px;

            span {
              &:first-child {
                font-weight: bold;
              }
            }
          }
        }
      }
    }

    .pager {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 20px;

      .size {
        margin-right: 20px;

        .el-select {
          width: 100px;
        }
      }

      .el-pagination {
        margin-top: 0 !important;
      }
    }
  }
}
</style>
